import React from "react";
import { useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout/layout";
import BlogCard from "../../components/global/blog-card";
import Seo from "../../components/seo/seo";
import { graphql } from "gatsby";
import HeaderBlog from "../../components/headers/header-blog";
import ImgDeclarative from "../../assets/images/blog/declarative-design-system.png";
import ImgRebranding from "../../assets/images/blog/ewasoft.jpg";
import ImgFonboarding from "../../assets/images/blog/fonboarding.jpg";
import ImgPm from "../../assets/images/blog/pm1.png";
import {useLocation} from "@reach/router";
import { navigate } from "gatsby-link";

const Blog = () => {
  const { t } = useTranslation();

  const uselocation = useLocation();

  const { pathname } = uselocation;

  let splitLocation = pathname.split("/")[1];
  useEffect(() => {
    if (splitLocation === 'de') {
      navigate(`/blog/`)
    }
  }, []);

  const blogContent = [
    {
      date: `${t("blog.fonboarding.date")}`,
      img: ImgFonboarding,
      title: `${t("blog.fonboarding.title")}`,
      text: `${t("blog.fonboarding.description")}`,
      btnHref: "/blog/fonboarding/",
    },
    {
      date: `${t("blog.delays.date")}`,
      img: ImgPm,
      title: `${t("blog.delays.title")}`,
      text: `${t("blog.delays.description")}`,
      btnHref: "/blog/the-major-causes-of-project-delays/",
    },
    {
      date: `${t("blog.rebranding.date")}`,
      img: ImgRebranding,
      title: `${t("blog.rebranding.title")}`,
      text: `${t("blog.rebranding.description")}`,
      btnHref: "/blog/rebranding-a-message-from-our-ceo/",
    },
  ];
  const headerContent = {
    title: "Our Blog",
    description:
      "Explore the many perspectives that make ewasoft, get tips and advice from our experts, follow up on some company news, and more.",
  };
  return (
    <Layout>
      <HeaderBlog headerContent={headerContent} />
      <Seo title="Blog" />
      <section className="m-blog">
        <div className="_wr">
          <div className="_w m-offer__wrap">
            {blogContent.map((item, i) => (
              <BlogCard item={item} key={i} cssModifer="-blockchain" />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
