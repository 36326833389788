import React from "react";
import HeaderBcg from "../../assets/images/headers/blog.png";

const HeaderBlog = ({ headerContent: { title, description, imageUrl } }) => {
  return (
    <header className="m-headerCareer">
      <div
        className="_wr m-headerCareer__wrapper -blog"
        // style={{ backgroundImage: `url("${imageUrl}")` }}
      >
        <div className="m-headerCareer__content _12 _l6">
          <h1 className="m-headerCareer__title">{title}</h1>
          <div className="m-headerCareer__info -blog">
            <p>{description}</p>
          </div>
        </div>
        <div
          className="m-headerMarkets__bkg -careers -blog _m5 _xl6"
          style={{ backgroundImage: `url("${HeaderBcg}")` }}
        ></div>
      </div>
    </header>
  );
};

export default HeaderBlog;
