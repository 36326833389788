import React, { useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const BlogCard = ({ item, cssModifer, styleClass }) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const renderText = (value) => {
    return value;
  };

  return (
    <Link to={item.btnHref} className="_m10  _l4  m-offer__itemContent -blog">
      <div className={`m-offer__item -fullHeight -blog`}>
        <div
          className="m-offer__item--image"
          style={{ backgroundImage: `url("${item.img}")` }}
        >
          {" "}
        </div>
        <div className="m-offer__item--date">{item.date}</div>
        <div className="m-offer__item--top">
          <div
            className={`m-offer__item--title -blog ${
              cssModifer ? cssModifer : ""
            }`}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        </div>
        <div className="m-offer__item--textContent">
          <p className="m-offer__item--text">{renderText(item.text)}</p>

          <div className="m-cardLinks">
            <span
              to={item.btnHref}
              className="a-btn -tertiary -offer"
              onClick={() => setShowMore(!showMore)}
            >
              {t(showMore ? "buttons.readLess" : "buttons.readMore")}
            </span>
            <span className="a-arrow -long"></span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
